@import "~@ui/styles/tools";
@import "../../../styles/variables";

.company-card {
    width: 296px;
    background-color: $color-page-background;
    border-radius: 12px;
    overflow: hidden;

    user-select: none;
}

.image {
    height: 124px;
    position: relative;
    overflow: hidden;

    img {   
        height: auto !important;
        top: 50% !important;
        transform: translateY(-50%);
    }
}

.info {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.name {
    font-size: $font-size--xlarge;
    font-weight: 500;
    text-transform: uppercase;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-bottom: 4px;
}

.description {
    color: #a5a8ad;
    font-size: $font-size--xsmall;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;
}

.amount {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 12px;
}

.info-table {
    border-top: 1px solid #EBEEEF;
    padding: 12px 0;
    margin-bottom: 12px;

    display: grid;
    grid-template-columns: repeat(3, max-content);
    justify-content: space-between;
}

.table-item {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.table-item-title {
    color: #a5a8ad;
    font-size: $font-size--small;
}

.table-item-value {
    font-size: $font-size--normal;
    font-weight: 500;
}

.button {
    width: 100%;
}

@include media-tablet {
    .name {
        font-size: $font-size--normal;
    }

    .amount {
        font-size: $font-size--xlarge;
    }
}