@import "~@ui/styles/tools";

.container {
    position: relative;
    overflow-x: hidden;

    &:hover {
        .arrows {
            opacity: 1;
        }
    }

    @include media-tablet-portrait {
        & > .slim {
            width: 80%;

            // Needed for centering indicators: 100% - width = margin-left
            &>div>ul {
                margin-left: 20%;
            }
        }
    }
}

.item-wrapper {
    margin: 0 20px;
}

.item-card {
    height: 100%;
}

.slider {
    > div {
        display: flex;
    }

    :global(.slick-slide) > div {
        height: 100%;
    }
}


.arrows {
    position: absolute;
    top: 50%;
    padding: 10px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.75);
    border: none;
    z-index: 2;
    opacity: 0;
    transition: 0.5s ease;

    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}

.arrow-right {
    right: 20px;
}

.arrow-left {
    left: 20px;
}